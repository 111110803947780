import React, { useState, useEffect, useCallback } from "react";
import MsgBoxModal from "../MsgBoxModal/msgbox-modal.component";
import { useDropzone } from "react-dropzone";
import { DropZone, ErrorMsg } from "./styles";
import { getAccessToken } from "../../helpers/functions";
import { useToasts } from "react-toast-notifications";
import { MAX_FILE_SIZE } from "../../helpers/file-upload";
import { ATTACHMENT_TYPE_SELECT_OPTIONS } from "./template-messages.comonent";

const UploadTemplateAttachment = ({
	isOpen,
	cancel,
	closeModal,
	setUploadedAttachmentId,
	setUploadedAttachmentType,
}) => {
	const [attachments, setAttachments] = useState([]);
	const [attachmentType, setAttachmentType] = useState("");
	const [caption, setCaption] = useState("");
	const [error, setError] = useState(false);
	const [errorMsg, setErrorMsg] = useState("");
	const { addToast } = useToasts();
	const [loading, setLoading] = useState(false);

	const onDrop = useCallback(
		(newAttachment) => {
			setAttachments(newAttachment);
		},
		[attachments]
	);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		maxFiles: 1,
	});

	useEffect(() => {
		if (attachments.length > 0) {
			const type = attachments[0].type;
			if (type === "application/pdf") setAttachmentType("pdf");
			else if (type.includes("image")) setAttachmentType("image");
			else if (type.includes("video")) setAttachmentType("video");
			else setAttachmentType("doc");
		}
	}, [attachments]);

	const upload = async () => {
		if (attachments.length <= 0) {
			setError(true);
			setErrorMsg("Attachment is required");
		} else if (attachments[0].size > MAX_FILE_SIZE) {
			setError(true);
			setErrorMsg("Attachment size too large (max 100MB)");
		} else {
			try {
				setLoading(true);
				const { name, type } = attachments[0];
				const signedUrlResponse = await fetch(
					`${process.env.REACT_APP_API_URL}filessignedurl`,
					{
						method: "POST",
						headers: {
							Authorization: `Bearer ${await getAccessToken()}`,
							"content-type": type,
						},
					}
				);
				const { attachmentId, presignedUrl } =
					await signedUrlResponse.json();
				setUploadedAttachmentId(attachmentId);

				const attachmentResponse = await fetch(presignedUrl, {
					method: "PUT",
					headers: {
						"Content-Disposition": `filename=${name}`,
						"Content-Type": type,
					},
					body: attachments[0],
				});

				if (
					attachmentResponse.status === 200 ||
					attachmentResponse.status === 201
				) {
					if (attachmentType === "image") {
						setUploadedAttachmentType(
							ATTACHMENT_TYPE_SELECT_OPTIONS.find(
								(x) => x.value === "Image"
							)
						);
					} else if (attachmentType === "video") {
						setUploadedAttachmentType(
							ATTACHMENT_TYPE_SELECT_OPTIONS.find(
								(x) => x.value === "Video"
							)
						);
					} else {
						setUploadedAttachmentType(
							ATTACHMENT_TYPE_SELECT_OPTIONS.find(
								(x) => x.value === "Document"
							)
						);
					}
				} else {
					addToast("error uploading attachment", {
						appearance: "error",
						autoDismiss: true,
					});
					console.error("failed:", attachmentResponse);
				}
				setLoading(false);
				destroyModal();
			} catch (error) {
				console.log("error", error);
				addToast("error uploading attachment", {
					appearance: "error",
					autoDismiss: true,
				});
				setLoading(false);
			}
		}
	};

	const destroyModal = () => {
		setAttachments([]);
		setCaption("");
		closeModal();
		setError(false);
		setAttachmentType("");
	};

	const attachmentIcon = () => {
		if (attachmentType === "pdf")
			return <i className="fas fa-file-pdf"></i>;
		if (attachmentType === "image") return <i className="far fa-image"></i>;
		return <i className="fas fa-file"></i>;
	};

	return (
		<MsgBoxModal
			aria={{
				labelledby: "heading",
				describedby: "description",
			}}
			header="Upload Attachment"
			description="Drag and drop to add an attachment below"
			isOpen={isOpen}
			cancel={() => {
				cancel();
				destroyModal();
			}}
			onRequestClose={() => {
				cancel();
				destroyModal();
			}}
			submitButtonName="Upload Attachment"
			submit={upload}
			submitting={loading}
		>
			<div>
				<div style={{ position: "relative" }}>
					<DropZone {...getRootProps({ className: "dropzone" })}>
						<input {...getInputProps()} />
						{attachments.length > 0 ? (
							<div className="placeholder">
								{attachmentIcon()}

								<p>{attachments[0].name}</p>
							</div>
						) : (
							<p>Drag a file here, or click to select a file.</p>
						)}
					</DropZone>
					{error && <ErrorMsg>{errorMsg}</ErrorMsg>}
				</div>
			</div>
		</MsgBoxModal>
	);
};

export default UploadTemplateAttachment;
